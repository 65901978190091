.sidebar {
  /* height: 100%; /* 100% Full-height */
  /*width: 200px; /* 0 width - change this with JavaScript */
  position: fixed; /* Stay in place */
  z-index: 1; /* Stay on top */
  top: 150px; /* Stay at the top */
  left: 0;
  background-color: #e57373 ; /* red lighten 3*/
  overflow-x: hidden; /* Disable horizontal scroll */
  /*padding-top: 2em; /* Place content 60px from the top */
  padding-left: 1em;
  padding-right: 2em;
  border-radius: 0 30px 30px 0;
  transition: 0.5s; /* 0.5 second transition effect to slide in the sidenav */
}