/* label color */
.input-field label {
    color: #000;
}
/* label focus color */
.input-field input[type=text]:focus + label {
    color: #e57373 !important;
}
/* label underline focus color */
.input-field input[type=text]:focus {
    border-bottom: 1px solid #e57373 !important;
    box-shadow: 0 1px 0 0 #e57373 !important;
}
/* valid color */
.input-field input[type=text].valid {
    border-bottom: 1px solid #e57373 !important;
    box-shadow: 0 1px 0 0 #e57373 !important;
}
/* invalid color */
.input-field input[type=text].invalid {
    border-bottom: 1px solid #000;
    box-shadow: 0 1px 0 0 #000;
}
/* icon prefix focus color */
.input-field .prefix.active {
    color: #000;
}