body {
/*background-color: #e57373 ; */
font-family: 'Didot';
margin: 0;
padding: 0;
}
.brand-logo {
width: 80%;    
}
.burger {
cursor: pointer;    
}
@media (orientation: portrait) {
footer {
height: 100%;    
}
}